<template>
  <div class="brand-content" v-loading="formLoading">
    <el-form v-if="Form" size="medium" :model="Form" :rules="rules" label-position="right" label-width="140px"
      class="small-form" ref="Form" @submit.native.prevent>
      <div class="modular">
        <p class="modular-title">积分设置</p>
        <div class="modular-content">
          <el-form-item label="开启状态：" prop="open_integral">
            <el-radio v-model="Form.open_integral" :label="1">开启</el-radio>
            <el-radio v-model="Form.open_integral" :label="0">关闭</el-radio>
          </el-form-item>
          <el-form-item label="每日积分获取上限：" v-if="Form.open_integral" prop="daily_integral_upper">
            <el-input type="number" v-model.number="Form.daily_integral_upper" placeholder="请输入积分获取上限">
            </el-input>
            <div class="form-tip">
              <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
              <span>用户每日在操作中获得的积分上限值，为空则不设定上限，每天凌晨刷新。</span>
            </div>
          </el-form-item>
          <el-form-item label="每月积分获取上限：" v-if="Form.open_integral" prop="month_integral_upper">
            <el-input type="number" v-model.number="Form.month_integral_upper" placeholder="请输入积分获取上限">
            </el-input>
            <div class="form-tip">
              <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
              <span>用户每月在操作中获得的积分上限值，为空则不设定上限，每月1号刷新。</span>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title">成长值设置</p>
        <div class="modular-content">
          <el-form-item label="开启状态：" prop="open_level">
            <el-radio v-model="Form.open_level" :label="1">开启</el-radio>
            <el-radio v-model="Form.open_level" :label="0">关闭</el-radio>
          </el-form-item>
          <el-form-item label="每日成长值获取上限：" v-if="Form.open_level" prop="daily_exp_upper">
            <el-input type="number" v-model.number="Form.daily_exp_upper" placeholder="请输入成长值获取上限">
            </el-input>
            <div class="form-tip">
              <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
              <span>用户每日在操作中获得的成长值上限值，为空则不设定上限，每天凌晨刷新。</span>
            </div>
          </el-form-item>
          <el-form-item label="每月成长值获取上限：" v-if="Form.open_level" prop="month_exp_upper">
            <el-input type="number" v-model.number="Form.month_exp_upper" placeholder="请输入成长值获取上限">
            </el-input>
            <div class="form-tip">
              <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
              <span>用户每月在操作中获得的成长值上限值，为空则不设定上限，每月1号刷新。</span>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title">等级排行设置</p>
        <div class="modular-content">
          <!-- "level_rank_all_user":1,//积分排行配置 0：仅会员可参与排行，1：所有用户可参与排行 -->
          <el-form-item label="谁可参与排行：" prop="level_rank_all_user">
            <el-radio-group v-model="Form.level_rank_all_user">
              <el-radio :label="1">所有用户可参与排行</el-radio>
              <el-radio :label="0">仅会员可参与排行</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title">积分换算</p>
        <div class="modular-content">
          <el-form-item label="开启状态：" prop="open_integral_to_exp">
            <el-radio v-model="Form.open_integral_to_exp" :label="1">开启</el-radio>
            <el-radio v-model="Form.open_integral_to_exp" :label="0">关闭</el-radio>
          </el-form-item>
          <div v-if="Form.open_integral_to_exp" class="custom-form">
            <p>换算比例设置：</p>
            <el-form-item label-width="0px" prop="from_integral">
              <el-input type="number" v-model.number="Form.from_integral" placeholder="请输入积分">
                <span slot="suffix">积分</span>
              </el-input>
            </el-form-item>
            <div style="margin: 0 10px">=</div>
            <el-form-item label-width="0px" prop="to_exp">
              <el-input type="number" v-model.number="Form.to_exp" placeholder="请输入成长值">
                <span slot="suffix">成长值</span>
              </el-input>
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title">等级说明</p>
        <div class="modular-content">
          <el-form-item label="跳转链接：" prop="level_desc_link">
            <el-button class="jump_link" type="default" plain @click="showLinkConfigDialog = true">
              {{typeof Form.level_desc_link === 'object'?Form.level_desc_link.jump_config.name:'设置链接地址'}}
            </el-button>
            <div class="form-tip">
              <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
              <span>前端成长等级页面中，等级说明的跳转地址</span>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <link-config-dialog v-model="showLinkConfigDialog" :requestType="['content_link']" @change="linkChange" />
    <FixedActionBar>
      <el-button type="primary" size="medium" @click="formSubmit('Form')" :loading="saveLoading">保存
      </el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { configDetail, saveConfig } from "../api/income-setting";
import FixedActionBar from "@/base/layout/FixedActionBar";
import LinkConfigDialog from "../../app-design/components/Normal/LinkConfigDialog.vue";
export default {
  components: {
    FixedActionBar,
    LinkConfigDialog,
  },
  data() {
    return {
      Form: null,
      rules: {
        from_integral: [
          { required: true, message: "请输入积分", trigger: "blur" },
        ],
        to_exp: [{ required: true, message: "请输入成长值", trigger: "blur" }],
      },
      formLoading: false,
      saveLoading: false,
      showLinkConfigDialog: false,
    };
  },
  methods: {
    // 获取详情
    getConfigDetail() {
      this.formLoading = true;
      configDetail()
        .then((res) => {
          const { data } = res;
          this.Form = data;
          this.formLoading = false;
        })
        .catch((err) => {
          this.formLoading = false;
        });
    },
    // 选择跳转链接后
    linkChange(val) {
      this.Form.level_desc_link = val;
    },
    // 保存
    formSubmit(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          const data = { ...this.Form };
          this.saveLoading = true;
          saveConfig({ config: data })
            .then((res) => {
              this.$message.success(res.msg);
              this.getConfigDetail();
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({ block: "center" });
          }, 100);
          this.$message.error("请填写完整信息");
          return false;
        }
      });
    },
  },
  created() {
    this.getConfigDetail();
  },
};
</script>

<style lang="scss" scoped>
.modular-content {
  margin-left: 11px;
}
.brand-content {
  .add-host:hover {
    border-color: #409eff;
  }

  .input-imgs {
    display: flex;
  }

  .footer-btn {
    @include fixed-card-bottom();
    padding-left: 250px;
  }

  .input-item {
    width: 194px;
    text-align: center;
    margin-right: 100px;

    .images {
      width: 194px;
      height: 135px;
      cursor: pointer;
    }

    .upload-btn {
      width: 194px;
      height: 135px;
      border: 1px dashed #d9d9d9;
      .plus-icon {
        font-size: 20px;
        .upload-text {
          font-size: 14px;
          line-height: 30px;
        }
      }
    }

    .info-title {
      font-size: 14px;
      line-height: 30px;
    }

    .img-tips {
      margin-top: 8px;
      font-size: 12px;
      color: #c0c4cc;
      line-height: 15px;
    }
    .upload-btn:hover {
      border-color: #409eff;
    }
  }
  .custom-form {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    .el-form-item {
      margin-bottom: 0;
    }
    p {
      width: 140px;
      text-align: end;
      font-size: 14px;
      white-space: nowrap;
      color: #606266;
      &::before {
        content: "*";
        color: red;
        margin-right: 4px;
      }
    }
    ::v-deep.el-input__suffix {
      right: 10px;
    }
  }
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
</style>
