import api from "@/base/utils/request";

// 获取基本设置
export const configDetail = data => {
  return api({
    url: "/admin/incomes/settings/getUserIntegralLevelConfig",
    method: "post",
    data
  });
};

// 保存基本配置
export const saveConfig = data => {
  return api({
    url: "/admin/incomes/settings/setUserIntegralLevelConfig",
    method: "post",
    data
  });
};